import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../../core/step.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-counter',
  templateUrl: './step-counter.component.html',
  styleUrls: ['./step-counter.component.css']
})
export class StepCounterComponent implements OnInit {
  @Input() page: string;

  constructor(
    private steps: StepService,
    private router: Router,
  ) { }

  selectProduct() {
    this.router.navigateByUrl('');
  }

  selectAutoship() {
    this.router.navigateByUrl('autoship');
  }

  ngOnInit() {
  }

}
