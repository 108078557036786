import { Injectable } from '@angular/core';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { SponsorInfo } from './sponsor-info';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {
  private _sponsor: BehaviorSubject<SponsorInfo> = new BehaviorSubject(null)

  constructor(
    private router: Router,
    private afs: AngularFireFunctions,
  ) {
    this._sponsor.next(JSON.parse(localStorage.getItem('sponsor')))
  }

  get sponsor() {
    return this._sponsor.asObservable()
  }

  checkSponsor() {
    var tmp_sponsor = localStorage.getItem('sponsor')
    if (!tmp_sponsor) {
      return this.router.navigateByUrl('no-sponsor')
    }
  }

  setSponsor(sponsorArray) {
    console.log('setting sponsor')
    const data = {
      id: sponsorArray[1],
      name: sponsorArray[2],
      sitename: sponsorArray[5],
      phone: sponsorArray[3],
    }
    this._sponsor.next(data)
    localStorage.setItem('sponsor', JSON.stringify(data))
  }

  testSponsor(sponsor: string) {
    this.verify(sponsor)
  }

  async verify(sponsorID: string) {
     const scheck = this.afs.httpsCallable('sponsorCheck');
     scheck({sponsorid: sponsorID})
      .toPromise()
      .then(resp => {
        const sponsorString = <string>resp;
        const sponsorArray = sponsorString.split('|')
        if (sponsorArray[0] === '0'){
          console.log(sponsorArray);
          this.setSponsor(sponsorArray)
        } else {
            this.router.navigateByUrl('/no-sponsor')

        }
      })
      .catch(err => {
        console.error({ err });
      });
   }

}
