import { Pipe, PipeTransform } from '@angular/core';
import { Product } from './core/product';

@Pipe({
  name: 'priceSort'
})
export class PriceSortPipe implements PipeTransform {

  transform(products: Product[], args?: any): Product[] {
    if (!products) return products;
    return products.sort((a: Product, b: Product) => {
      return a.price > b.price ? -1 : 1;
    })
  }

}
