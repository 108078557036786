import { Component, OnInit } from '@angular/core';
import { CartService } from '../../core/cart.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import {ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.css']
})
export class SandboxComponent implements OnInit {
  products: Observable<any[]>;

  constructor(
    private cart: CartService,
    private db: AngularFirestore,
    private router: Router,
  ) {
    this.products = db.collection('products').valueChanges()
  }

  test_routing() {
      this.router.navigateByUrl('');
  }

  ngOnInit() {
  }
}
