import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SandboxComponent } from './sandbox/sandbox.component';
import { SelectProductComponent } from './select-product/select-product.component';
import { CartBoxComponent } from './cart-box/cart-box.component';
import { HeaderComponent } from './header/header.component';
import { StepCounterComponent } from './step-counter/step-counter.component';
import { MoreInfoComponent } from './more-info/more-info.component';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SelectAutoshipComponent } from './select-autoship/select-autoship.component';
import { NoSponsorComponent } from './no-sponsor/no-sponsor.component';
import { PriceSortPipe } from '../price-sort.pipe';
import { PricePipe } from '../price.pipe';
import { DialogModule } from 'primeng/dialog';
import { SelectCountryComponent } from './select-country/select-country.component';
import { CurPipe } from './cur.pipe';
import { FeaturedPipe } from '../featured.pipe';


@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    DialogModule,
  ],
  providers: [MessageService],
  declarations: [SandboxComponent, SelectProductComponent, CartBoxComponent, HeaderComponent, StepCounterComponent, MoreInfoComponent, SelectAutoshipComponent, NoSponsorComponent, PriceSortPipe, PricePipe, SelectCountryComponent, CurPipe, FeaturedPipe],
  exports: [SelectCountryComponent,  HeaderComponent]
})
export class UiModule { }
