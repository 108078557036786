import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectProductComponent } from './ui/select-product/select-product.component';
import { SelectAutoshipComponent } from './ui/select-autoship/select-autoship.component';
import { SandboxComponent } from './ui/sandbox/sandbox.component';
import {  NoSponsorComponent } from './ui/no-sponsor/no-sponsor.component';

const routes: Routes = [
  { path: '', component: SelectProductComponent },
  { path: 'autoship', component: SelectAutoshipComponent },
  { path: 'sandbox', component: SandboxComponent },
  { path: 'no-sponsor', component: NoSponsorComponent },
  { path: ':sponsor', component: SelectProductComponent },
  { path: 'autoship/:sponsor', component: SelectAutoshipComponent }

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
