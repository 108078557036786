import { Injectable } from '@angular/core';
import { CartService } from './cart.service';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { CartItem } from './cart-item';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  _steps: BehaviorSubject<any> = new BehaviorSubject({})
  constructor(
    private cartService: CartService,
  ) {
    var tmp_steps = {}
    tmp_steps['cartItems'] = cartService.checkCart()
    tmp_steps['autoItems'] = cartService.checkAsCart()
    tmp_steps['checkOut']  = false
    this._steps.next(tmp_steps)
  }

  get steps() {
    return this._steps.asObservable()
  }

  markStepOne() {
    console.log('testing')
    var tmp_steps = this._steps.getValue()
    tmp_steps['cartItems'] = true
    this._steps.next(tmp_steps)
  }
}
