import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'featured'
})
export class FeaturedPipe implements PipeTransform {

  transform(items: any[], show?: boolean): any {
    if (items === undefined || items === null) return items
    return items.filter(function(item){
      if (item.e_top || show) {
        return item
      }
    })
  }

}
