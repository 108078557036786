import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  /**
  * @param prices the list of prices [cart_price,as_price,cart_wp,as_wp]
  * @param cart 0: Enrollment Cart 1: Autoship Cart
  */
  transform(prices: number[], cart: number): number {
    if (!prices || cart > 2) return null;
    if (prices[3] >= 1) {
      return prices[(cart+2)]
    } else return prices[cart]
  }

}
