import { Component, OnInit } from '@angular/core';
import { CartService } from '../../core/cart.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageService } from 'primeng/api';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { CountryService } from '../../core/country.service';
import { FeaturedPipe } from '../../featured.pipe';

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  animations: [
    trigger('myAwesomeAnimation', [
        state('white', style({
            background: 'white',
        })),
        state('green', style({
            background: 'green',
        })),
        transition('white => green', animate('100ms ease-in')),
    ]),
  ],
  styleUrls: ['./select-product.component.css']
})
export class SelectProductComponent implements OnInit {

 featured: Observable<any[]>;
 products$: Observable<any[]>;
 free$: Observable<any>
 state: string = 'white';
 carePacks$: Observable<any[]>;
 product_type: string = 'featured';
 curCode: string = null;
 showFlags: boolean[] = [false,false,false]
 cc:string = 'US';

  constructor(
    private cart: CartService,
    private db: AngularFirestore,
    private messageService: MessageService,
    private country: CountryService,
    private route: ActivatedRoute,
  ) {
    this.country.country.subscribe((cc) => {
      if (cc) {
        this.cc=cc.cc;
        var productsRef = db.collection('products', ref => ref.where('en_item', '==', true).where('featured','==',true).where('lcc', 'array-contains', cc.cc));
        this.featured = productsRef.valueChanges();
        var carePackRef = db.collection('products', ref => ref.where('care_pack', '==', true).where('lcc', 'array-contains', cc.cc));
        this.carePacks$ = carePackRef.valueChanges();
        var freeRef = this.db.doc('products/FREE')
        this.free$ = freeRef.valueChanges();
        var productsRef = this.db.collection('products', ref => ref.where('en_item', '==', true).where('individual','==',true).where('lcc', 'array-contains', cc.cc));
        this.products$ = productsRef.valueChanges();

        this.curCode=cc.ctype

        if (cc.cc == 'AUS'|| cc.cc=='NZ' || cc.cc=='UK' || cc.cc=='AU') {
          this.featured = carePackRef.valueChanges();
          this.showFlags = [true,true,false]
        }
      }
    })
    this.route.queryParams.subscribe(qParams => {
      if (qParams['pre-select']) {
        if (qParams['pre-select'] == 'carePacks') {
          this.product_type = 'carePacks';
          this.cart.emptyCart();
          this.cart.as_emptyCart();
        } else if (qParams['pre-select'] == 'products') {
          this.product_type = 'products';
          this.cart.emptyCart();
          this.cart.as_emptyCart();
        }
      }
    })

  }

  loadProducts() {
    var productsRef = this.db.collection('products', ref => ref.where('en_item', '==', true).where('featured','==',false).where('lcc', 'array-contains', 'US'));
    this.products$ = productsRef.valueChanges();
    var freeRef = this.db.doc('products/FREE')
    this.free$ = freeRef.valueChanges();
  }

  addToCartMessage(name: string) {
    this.messageService.add({severity:'success', summary: name, detail:'Was Added to your cart!'});
  }

  ngOnInit() {

  }

}
