import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../../core/cart.service';
import { SponsorService } from '../../core/sponsor.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StepService } from '../../core/step.service';
import { DialogModule } from 'primeng/dialog';
import { CountryService } from '../../core/country.service';


@Component({
  selector: 'app-cart-box',
  templateUrl: './cart-box.component.html',
  styleUrls: ['./cart-box.component.css']
})
export class CartBoxComponent implements OnInit {
  @Input() page: Observable<string>;
  sponsor: Observable<string>;
  dbox: boolean = false;
  ccode: string = 'US';
  curCode: string = null;

  constructor(
    private cart: CartService,
    private router: Router,
    private route: ActivatedRoute,
    private sservice: SponsorService,
    private steps: StepService,
    private country: CountryService,
  ) {
    this.route.params.subscribe(routeParams => {
      if (routeParams['sponsor']) {
        sservice.testSponsor(routeParams['sponsor'])
      } else {
        sservice.checkSponsor()
      }
    });
    this.country.country.subscribe(cc => {
      if (cc) {
        this.ccode = cc.cc
        this.curCode = cc.ctype
      }
    })
    this.route.queryParams.subscribe(qParams => {
      if (qParams['cc']) {
        this.country.setCountry('US')
      }
      if (qParams['product']) {
        // try {
        //   cart.emptyCart(); cart.as_emptyCart()
        //   cart.addItem(qParams['product'],1)
        // }
        // catch(TypeError) {
        //   console.log('caught')
        // }
        steps.markStepOne()

        cart.emptyCart(); cart.as_emptyCart()
        cart.addItem(qParams['product'],1)
      }
    })
  }

  checkStage() {
    console.log(this.page)
  }

  underAuto() {
    this.dbox = true;
  }

// https://www.cs4000.net/CareBeyond/enroll/signup_form_prod.asp?
  enroll() {
    var elink = 'https://www.cs4000.net/CareBeyond/enroll/signup_form_prod.asp?sponsorid='
    this.sservice.sponsor.subscribe(spons => {
      this.cart.link.subscribe(link => {
        elink = elink + spons.id + '&' + link
        console.log(elink);
        window.location.href=elink;
      }).unsubscribe()
    }).unsubscribe()
  }

  selectAutoship() {
    this.steps.markStepOne()
    this.router.navigateByUrl('autoship');
  }

  selectEnrollment() {
    this.router.navigateByUrl('');
  }

  ngOnInit() {
  }

}
