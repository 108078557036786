import { Component, OnInit } from '@angular/core';
import { SponsorService } from '../../core/sponsor.service';
import { CartService } from '../../core/cart.service';
import { CountryService } from '../../core/country.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  ccode: string = 'US';

  constructor(
    private sservice: SponsorService,
    private cart: CartService,
    private country: CountryService,
  ) {
    this.country.country.subscribe(cc => {
      if (cc) {
        this.ccode =cc.cc
      }
    })
  }

  ngOnInit() {
  }

}
