import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { NgbModal, NgbActiveModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.css']
})
export class SelectCountryComponent implements OnInit {
  @ViewChild('content', { static: true }) content: any;
  untouched: boolean = true;
  modalRef: NgbModalRef;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private country: CountryService,
    private config: NgbModalConfig,
    private cdr: ChangeDetectorRef,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    // this.openSelect()
    this.selectCountry("US");
  }

  selectCountry(cc:string) {
    this.country.setCountry(cc);
    this.modalRef.close("Submitted");
  }

  async openSelect() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(50);
    if (this.untouched) {
      this.untouched = false
      if(!this.country.checkCountryCookie()) {
        this.route.queryParams.subscribe(q => {
          if (q['cc']) {
            this.country.setCountry(q['cc']);
          } else {
            this.modalRef = this.modalService.open(this.content, {'centered': true,})
          }
        })
        // this.country.setCountry('US');
      } else {
        // console.log('nope')
      }
    } else {
      // console.log('touched')
    }
  }



  open(content) {
    this.modalService.open(content);
  }

  reSelect() {
    this.modalRef = this.modalService.open(this.content, {'centered': true,})
  }

}
