import { Component, OnInit } from '@angular/core';
import { CartService } from '../../core/cart.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageService } from 'primeng/api';
import { CountryService } from '../../core/country.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-autoship',
  templateUrl: './select-autoship.component.html',
  styleUrls: ['./select-autoship.component.css']
})
export class SelectAutoshipComponent implements OnInit {
  featured: Observable<any[]>;
  products$: Observable<any[]>;
  private curCode: string = null;
  cc: string = 'US';

  constructor(
    private route: ActivatedRoute,
    private cart: CartService,
    private db: AngularFirestore,
    private messageService: MessageService,
    private country: CountryService,
  ) {
    this.route.queryParams.subscribe((q) => {
      if (q['productID']) {
        this.cart.emptyCart();
        this.cart.as_emptyCart();
        this.cart.addItem(q['productID'], 1);
        // this.country.setCountry('US');
      }
    })

    this.country.country.subscribe((cc) => {
      if (cc) {
        this.cc=cc.cc
        var productsRef = db.collection('products', ref => ref.where('as_item', '==', true).where('featured','==',true).where('lcc', 'array-contains', cc.cc));
        this.featured = productsRef.valueChanges();

        this.curCode=cc.ctype;

        if (cc.cc == 'AUS' || cc.cc == 'UK' || cc.cc =='AU') {
          this.loadProducts()
        }
      }
    })
  }

  loadProducts() {
    this.country.country.subscribe((cc) => {
      if (cc) {
        var productsRef = this.db.collection('products', ref => ref.where('as_item', '==', true).where('featured','==',false).where('lcc', 'array-contains', cc.cc));
        this.products$ = productsRef.valueChanges();
      }
    })
  }

  ngOnInit() {
  }

}
