import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CartService } from '../../core/cart.service';
import { MessageService } from 'primeng/api';
import { CountryService } from '../../core/country.service';


@Component({
  selector: 'more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.css']
})
export class MoreInfoComponent implements OnInit {
  @Input() productID: string;
  @Input() page: string;
  @Input() noButton: boolean;
  @Input() productName: string;
  closeResult: string;
  modalRef: NgbModalRef;
  product: any;
  private curCode: string = null;
  private cc: string = null;

  constructor(
    private db: AngularFirestore,
    private modalService: NgbModal,
    private cart: CartService,
    private messageService: MessageService,
    private country: CountryService,
  ) {
    this.country.country.subscribe((cc) => {
      if (cc) {
        this.cc=cc.cc
        this.curCode=cc.ctype
      }
    })
  }

  ngOnInit() {
  }

  open(content) {
    console.log(this.productID)
    const productRef = this.db.doc(`products/${this.productID}`);
    productRef.valueChanges().subscribe((product) => {
      this.product = product
    });
    this.modalRef = this.modalService.open(content, {'centered': true, size: 'lg', windowClass: 'big-ol-box'});
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  addToCartMessage(name: string) {
    console.log("test")
    this.messageService.add({severity:'success', summary: name, detail:'Was Added to your cart!'});
  }

  changeProduct(sku: string) {
    const productRef = this.db.doc(`products/${sku}`);
    productRef.valueChanges().subscribe((product) => {
      this.product = product
    });
  }
}
