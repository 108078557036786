import { Injectable } from '@angular/core';
import { Country} from './country';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { CookieService } from 'angular2-cookie/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private _country: BehaviorSubject<Country> = new BehaviorSubject(null);
  clist = {
    'US': {
      name: 'United States',
        cc: 'US',
        ctype: null,
    },
    'EU': {
      name: 'Europe',
        cc: 'EU',
    },
    'AU':{
       name: 'Australia',
         cc: 'AU',
      ctype: 'AUD'
    },
    'UK': {
      name: 'United Kingdom',
        cc: 'UK',
    },
    'NZ': {
      name: 'New Zealand',
        cc: 'NZ',
        ctype: 'NZD',
    },
    'CA': {
      name: 'Canada',
        cc: 'CA',
        ctype: 'CAD',
    },
    'GB': {
        name: 'United Kingdom',
          cc: 'GB',
       ctype: 'GBP',
        flag: 'assets/images/flags/uk-flag.png'
    },
    'IR':{
       name: 'Ireland',
         cc: 'IR',
      ctype: 'EUR',
       flag: 'assets/images/flags/ir-flag.png'
    },
    'MX': {
      name: 'Mexico',
        cc: 'MX',
     ctype: 'MXN',
      flag: 'assets/images/flags/mx-flag.png'
    },
    'JP': {
      name: 'Japan',
        cc: 'JP',
        ctype: 'JPY',
        flag: 'assets/images/flags/jp-flag.png'
    },
  }

  constructor(private cookie: CookieService,) { }

  get country() {
    return this._country.asObservable()
  }

  getCountryCookies() {
    const country_tmp: Country = {
      cc: this.cookie.get('CountryCode').slice(1,-1),
      name: this.cookie.get('CountryName').slice(1,-1),
      ctype: this.clist[this.cookie.get('CountryCode').slice(1,-1)].ctype
    }
    // console.log(country_tmp)
    this._country.next(country_tmp)
  }

  setCountryCookies(c_data: Country) {
    this.cookie.putObject('CountryInfo', c_data, {'domain':'carebeyond.com'})
    this.cookie.putObject('CountryCode', c_data.cc, {'domain':'carebeyond.com'})
    this.cookie.putObject('CountryName', c_data.name, {'domain':'carebeyond.com'})
  }

  setCountry(cc: string) {
    var c_data = this.clist[cc]
    this._country.next(c_data)
    this.setCountryCookies(c_data)
  }

  checkCountryCookie() {
     const countryInfo = this.cookie.get('CountryInfo')
     if (countryInfo) {
       const tmp_country = this._country.getValue()
       if (tmp_country) {
         return true
       } else {
         this.getCountryCookies()
       }
     } else {
       return false
     }
     return true
   }
}
