import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { CartItem } from './cart-item';
import { Product } from './product';
import { CountryService } from './country.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  showCart: boolean = false;
  private _cart: BehaviorSubject<CartItem[]> = new BehaviorSubject([])
  private _as: BehaviorSubject<CartItem[]> = new BehaviorSubject([])
  private _clink: BehaviorSubject<string> = new BehaviorSubject('')
  private _price: BehaviorSubject<number[]> = new BehaviorSubject([0,0,0,0,0])
  private _vols: BehaviorSubject<number[]> = new BehaviorSubject([0,0,0,0])
  products$: Observable<any[]>;
  private _productDic: BehaviorSubject<{[sku: string]: Product} | null> = new BehaviorSubject(null)
  private cc: string = 'US';

  constructor(
    db: AngularFirestore,
    private country: CountryService,
  ) {
      var productDic = {}
      this.products$ = db.collection('products').valueChanges();
      this.products$.subscribe((products) => {
        for (let product of products) {
          productDic[product.productID] = product
        }
        this._productDic.next(productDic)
        this.updatePrice()
        this.updateVols()
      });
      this.country.country.subscribe((cc) => {
        if (cc) {
          this.cc = cc.cc
        }
      })

      // console.log('checking storage')
      this._cart.next(null)
      this._as.next(null)
      this.cartChange()
      this.updateLink()
  }

  get cart() {
    return this._cart.asObservable();
  }

  get as_cart() {
    return this._as.asObservable();
  }

  get link() {
    return this._clink.asObservable();
  }

  get prices() {
    return this._price.asObservable();
  }

  get vols() {
    return this._vols.asObservable();
  }

  get productDic() {
    return this._productDic.asObservable();
  }

  addItem(sku: string, quantity: number) {
    const item: CartItem = {
      sku: sku,
      quantity: quantity,
    }
    var tmp_cart = JSON.parse(localStorage.getItem('cart'))
    if(tmp_cart) {
      // console.log(tmp_cart)
      var flag = 0
      for (let i in tmp_cart){
        if (sku === tmp_cart[i].sku){
          tmp_cart[i].quantity += quantity
          flag = 1
          break
        }
      }
      if (flag == 0){
        tmp_cart.push(item)
      }
    } else {
      // console.log('Starting a new Cart')
      tmp_cart = new Array()
      tmp_cart.push(item);
    }

    localStorage.setItem('cart', JSON.stringify(tmp_cart));
    this._cart.next(tmp_cart)
    this.cartChange()
  }

  as_addItem(sku: string, quantity: number) {
    const item: CartItem = {
      sku: sku,
      quantity: quantity,
    }
    var tmp_cart = this._as.getValue()
    if (tmp_cart) {
      var flag = 0
      for (let i in tmp_cart){
        if (sku === tmp_cart[i].sku){
          tmp_cart[i].quantity += quantity
          flag = 1
          break
        }
      }
      if (flag == 0){
        tmp_cart.push(item)
      }
    } else {
      // console.log('Starting a new AS Cart')
      tmp_cart = new Array()
      tmp_cart.push(item);
    }

    localStorage.setItem('as_cart', JSON.stringify(tmp_cart));
    this._as.next(tmp_cart)
    this.cartChange()
  }

  as_setQuant(sku: string, quantity: number) {
    const item: CartItem = {
      sku: sku,
      quantity: quantity,
    }
    var tmp_cart = this._as.getValue()
    if (tmp_cart) {
      var flag = 0
      for (let i in tmp_cart){
        if (sku === tmp_cart[i].sku){
          tmp_cart[i].quantity = quantity
          flag = 1
          break
        }
      }
      if (flag == 0){
        tmp_cart.push(item)
      }
    } else {
      // console.log('Starting a new AS Cart')
      tmp_cart = new Array()
      tmp_cart.push(item);
    }

    localStorage.setItem('as_cart', JSON.stringify(tmp_cart));
    this._as.next(tmp_cart)
    this.isEmpty()
    this.cartChange()
  }

  removeAll(sku: string) {
    var tmp_cart = JSON.parse(localStorage.getItem('cart'))
    if (!tmp_cart){
      // console.log('empty cart already')
      return
    }
    for (let i in tmp_cart){
      if (sku === tmp_cart[i].sku){
        tmp_cart = tmp_cart.slice(0,i).concat(tmp_cart.slice(Number(i)+1))
        break
      }
    }
    localStorage.setItem('cart', JSON.stringify(tmp_cart));
    this._cart.next(tmp_cart)
    // console.log("Sku removed from cart")
    this.isEmpty()
    this.cartChange()
  }

  as_removeAll(sku: string) {
    var tmp_cart = this._as.getValue()
    if (!tmp_cart){
      // console.log('empty cart already')
      return
    }
    for (let i in tmp_cart){
      if (sku === tmp_cart[i].sku){
        tmp_cart = tmp_cart.slice(0,Number(i)).concat(tmp_cart.slice(Number(i)+1))
        break
      }
    }
    localStorage.setItem('as_cart', JSON.stringify(tmp_cart));
    this._as.next(tmp_cart)
    // console.log("Sku removed from cart")
    this.isEmpty()
    this.cartChange()
  }

  removeItem(sku: string) {
    var tmp_cart = this._cart.getValue()
    if (!tmp_cart){
      // console.log('empty cart already')
      return
    }
    for (let i in tmp_cart){
      if (sku === tmp_cart[i].sku){
        tmp_cart[i].quantity -= 1
        if (tmp_cart[i].quantity == 0) {
          tmp_cart = tmp_cart.slice(0,Number(i)).concat(tmp_cart.slice(Number(i)+1))
        }
        break
      }
    }
    localStorage.setItem('cart', JSON.stringify(tmp_cart));
    this._cart.next(tmp_cart)
    this.isEmpty()
    this.cartChange()
  }

  as_removeItem(sku: string) {
    var tmp_cart = this._as.getValue()
    if (tmp_cart.length == 0) {
      // console.log('AS is empty')
      return
    }
    for (let i in tmp_cart){
      if (sku === tmp_cart[i].sku){
        tmp_cart[i].quantity -= 1
        if (tmp_cart[i].quantity == 0) {
          tmp_cart = tmp_cart.slice(0,Number(i)).concat(tmp_cart.slice(Number(i)+1))
        }
        break
      }
    }
    localStorage.setItem('as_cart', JSON.stringify(tmp_cart));
    this._as.next(tmp_cart)
    this.isEmpty()
    this.cartChange()
  }

  emptyCart() {
    localStorage.removeItem('cart');
    this._cart.next(null)
    this.cartChange()
  }

  as_emptyCart() {
    localStorage.removeItem('as_cart');
    this._as.next(null)
    this.cartChange()
  }

  isEmpty() {
    if (JSON.parse(localStorage.getItem('cart')).length == 0) {
      this.emptyCart()
    }
    var tmp_cart = this._as.getValue()
    try {
      if (tmp_cart.length < 1) {
        this.as_emptyCart()
      }
    }
    finally { return }
  }

// https://www.cs4000.net/CareBeyond/enroll/signup_form_prod.asp?sponsorid=10000&productid=PRO-PACK,1%7CASSOCIATE-STARTER-PACK,1&asproductid=energize-pack,1%7Clean-pack,1

  updateLink() {
    var tmp_cart = JSON.parse(localStorage.getItem('cart'))
    var tmp_as = this._as.getValue()
    var ccode = ''
    var clink = 'productid='
    var cend = ''
    if (this.cc != 'US' && this.cc != 'CA' && this.cc != 'IR' && this.cc != 'GB' && this.cc != 'MX') {
      cend = '-'+this.cc
    }
    if (tmp_cart) {
      if (tmp_cart.length > 0){
        for (let item of tmp_cart){
          clink += item.sku+ cend +','+item.quantity +'|'
        }
      } else { clink = '' }
    } else { clink = '' }

    var aslink = '&asproductid='
    if (tmp_as) {
      if (tmp_as.length > 0){
        for (let item of tmp_as){
          aslink += item.sku+ cend  +','+item.quantity +'|'
        }
      } else { aslink = ''}
    } else { aslink = ''}
    var link;
    if (clink.length > 1) {
      clink = clink.slice(0,-1)
    }
    if (aslink.length > 1) {
      aslink = aslink.slice(0,-1)
    }
    this.country.country.subscribe(cc => {
      if (cc) {
        ccode = '&country='+cc.cc
        link = clink + aslink+ ccode
        return this._clink.next(link)
      }
    })

  }

  checkCart() {
    var tmp_cart = this._cart.getValue()
    if (tmp_cart) {
      return tmp_cart.length > 0
    }
    return false
  }

  checkAsCart() {
    var tmp_cart = this._as.getValue()
    if (tmp_cart) {
      return tmp_cart.length > 0
    }
    return false
  }

  updatePrice() {
    var c_price=0, c_wp = 0, as_price = 0, as_wp = 0
    const tmp_cart = this._cart.getValue()
    const tmp_as = this._as.getValue()
    const tmp_dic = this._productDic.getValue()
    if (tmp_cart) {
      for (let item of tmp_cart) {
        c_price += (item.quantity * tmp_dic[item.sku].pxc[this.cc]) * .9
        if (tmp_dic[item.sku].wpxc) {
          c_wp += (item.quantity * tmp_dic[item.sku].wpxc[this.cc])
        } else {
          c_wp += (item.quantity * tmp_dic[item.sku].wp)
        }
      }
    }
    if (tmp_as) {
      for (let item of tmp_as) {
        as_price += (item.quantity * tmp_dic[item.sku].price) * .9
        as_wp += (item.quantity * tmp_dic[item.sku].wp)
      }
    }
    this._price.next([c_price,as_price,c_wp,as_wp,(c_price - c_wp)])
  }

  updateVols() {
    var c_bv=0, c_qv=0, as_bv=0, as_qv=0
    const tmp_cart = this._cart.getValue()
    const tmp_as = this._as.getValue()
    const tmp_dic = this._productDic.getValue()
    if (tmp_cart) {
      for (let item of tmp_cart) {
        c_bv += (item.quantity * tmp_dic[item.sku].bv)
        c_qv += (item.quantity * tmp_dic[item.sku].qv)
      }
    }
    if (tmp_as) {
      for (let item of tmp_as) {
        as_bv += (item.quantity * tmp_dic[item.sku].bv)
        as_qv += (item.quantity * tmp_dic[item.sku].qv)
      }
    }
    this._vols.next([c_bv,c_qv,as_bv,as_qv])
  }

  private cartChange() {
    this.updateLink()
    this.updatePrice()
    this.updateVols()
  }

  toggleCart() {
    this.showCart = !this.showCart
  }

}
