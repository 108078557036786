import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cur'
})
export class CurPipe implements PipeTransform {

  transform(value: number, curType?: any): string {
    var curMap = {
      'USD': {
        mutip: 1,
        symbol: '$',
        ender: 'USD',
      },
      'AUD': {
        multip: 1.55,
        symbol: '$',
        ender: 'AUD',
      },
      'NZD': {
        multip: 1.67,
        symbol: '$',
        ender: 'NZD',
      },
      'CAD': {
        multip: 1.40,
        symbol: '$',
        ender: 'CAD',
      },
      'GBP': {
        multip: 0.81,
        symbol: '£',
        ender: 'GBP',
      },
      'EUR': {
        multip: 0.92,
        symbol: '€',
        ender: 'EUR',
      },
      'MXN': {
        multip: 23.68,
        symbol: '$',
        ender: 'MXN',
      },
      'JPY': {
        multip: 107.31,
        symbol: '¥',
        ender: 'JPY',
      }
    }
    if (curType) {
      return 'Estimated Price Conversion: '+curMap[curType].symbol+(value * curMap[curType].multip).toFixed(2)+'<span class="curType"> '+curMap[curType].ender+'</span>'

    } else {
      return '$'+value
    }

    return null;
  }

}
